@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply lg:text-6xl lg:my-9 md:text-5xl md:my-5 sm:text-3xl sm:my-2 my-2 text-3xl font-black drop-shadow-md text-center;
  }

  h2 {
    @apply lg:text-4xl lg:my-9 md:text-2xl md:my-5 sm:text-xl sm:my-2 my-2 text-xl font-black text-center;
  }

  h3 {
    @apply md:text-xl sm:text-lg text-lg text-center;
  }

  h4 {
    @apply md:text-base sm:text-sm text-sm text-center;
  }

  textarea {
    @apply min-h-[80px] max-h-40 border-2 border-[#526B16] rounded;
  }

  select {
    @apply min-h-[25px] text-center border-2 border-[#526B16] rounded;
  }

  .tooltip {
    @apply invisible absolute;
  }

  .has-tooltip:hover .tooltip {
    @apply visible z-50;
  }

  th {
    @apply text-xs text-center p-1 border;
  }

  td {
    @apply text-xs text-center p-1 border;
  }
}

body {
  font-family: "Helvetica", sans-serif;
}
